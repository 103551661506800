
#jellyweek-logo {
  border: none;

  img {
    max-width: 6.5em;
  }
}

#jelly-section {
  position: relative;
  background-color: white;
  margin-top: 4.3em;

  iframe {
    width: 100%;
    height: 100vh;
  }
}
