@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

#hero {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--mooving-gradient);
  background-size: var(--mooving-gradient-size);
  animation: var(--mooving-gradient-animation);
  height: 80vh;
  min-height: 600px;
}

.logo-hero {
  display: block;
  max-width: 50%;
  filter: drop-shadow(0 0 50px #00000022);


  @media screen and (max-width: 600px) {
    max-width: 80%;
  }
}

.forfaits {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  >.forfait {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    padding: 1rem;
    color: var(--yellow);
    line-height: 1.4rem;
    border: 1px solid var(--yellow);
    background: var(--yellow-transparent-5);
    border-radius: 6px;

    &.mandatory {
      color: var(--red);
      border-color: var(--red);
      background: var(--red-transparent-5);
    }

    >.prix {
      font-size: 2rem;
      font-weight: 700;
    }
  }
}

#partenaires {
  padding-top: 2rem;
  background: #ffffff;

  h1 {
    margin: 0 0 2rem;
    color: var(--brown);
    text-align: center;
    font-size: 1.2rem;
    text-transform: uppercase;
  }
}

.partenaires {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  gap: 2rem;

  >.container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    gap: 2rem;
    padding: 0 5rem 5rem;

    >.partenaire {
      border: 0;

      &:hover {
        border: 0;

        >img {
          transform: scale(1.1);
        }
      }

      >img {
        transition: transform 100ms ease-in-out;
        height: 5rem;
      }
    }

    @media screen and (max-width: 600px) {
      padding: 0 1.5rem;
    }
  }
}

.galery {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
}
