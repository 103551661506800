body {
  background: var(--brown);
  color: var(--beige);
  font-family: var(--inter);
  -webkit-font-smoothing: antialiased;
}

.container {
  max-width: 900px;
  margin: 0 auto;
  padding: 0 3rem;

  @media screen and (max-width: 600px) {
    padding: 0 1.5rem;
  }
}

section {
  margin: 0 0 5rem;
}

header {
  position: fixed;
  z-index: 2;
  width: 100vw;
  top: 0;
  padding: 1rem;
  background: rgba(0,0,0,.7);
  backdrop-filter: blur(15px);

  > .container {
    display: flex;
    gap: 3rem;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .logo {
      border: 0;
      transition: opacity 100ms ease-in-out;

      &:hover {
        opacity: .6;
      }

      > img {
        height: 2rem;
      }
    }

    > nav {
      display: flex;
      gap: 2rem;
      justify-content: center;
      flex-wrap: wrap;
      color: var(--beige);
      font-size: .875rem;
      font-weight: 600;
      text-transform: uppercase;
    }

    @media screen and (max-width: 600px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      padding: 0rem;

      .logo {
        display: none;
      }

      > nav {
        gap: .5rem 1rem;
        font-size: .75rem;
      }
    }
  }

  a {
    border-bottom-color: transparent;
  }
}


footer {
  background: var(--brown-darker);

  > .container {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 3rem;
    font-size: .85rem;
    font-weight: 600;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.footer-link {
  border-bottom-color: transparent;
  color: var(--beige);
}
