:root {
  --inter: Inter, -apple-system, BlinkMacSystemFont, Helvetica, Roboto, Arial, sans-serif;

  --brown: rgb(37, 36, 37);
  --brown-darker: rgb(31, 30, 31);
  --beige: rgb(250, 248, 242);
  --beige-transparent-30:rgb(250, 248, 242, .3);
  --yellow: rgb(246, 165, 7);
  --yellow-transparent-5:rgba(246, 165, 7, .05);
  --red: rgb(239, 108, 123);
  --red-transparent-5: rgb(239, 108, 123, .05);

  --mooving-gradient: linear-gradient(45deg, #FDCB8F, #E06487, #F77173, #914176);
  --mooving-gradient-size: 400% 400%;
  --mooving-gradient-animation: gradient 15s ease infinite;
}