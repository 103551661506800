h1 {
  margin: 0 0 1rem;
  font-size: 3rem;
  line-height: 3.5rem;
  font-weight: 700;
}

h2 {
  margin: 0 0 .5rem;
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 600;
  text-transform: uppercase;
}

p {
  margin: 0 0 1rem;
  color: var(--beige);
  font-size: 1rem;
  line-height: 1.7rem;

  &:last-child {
    margin: 0;
  }

  + h2 {
    margin: 2rem 0 .5rem;
  }
}

li {
  margin: 0 0 1rem 1rem;
  color: var(--beige);
  font-size: 1rem;
  line-height: 1.7rem;
}

strong {
  font-weight: 700;
}

a {
  display: inline-block;
  position: relative;
  color: var(--beige);
  text-decoration: none;
  border-bottom: 2px solid var(--beige-transparent-30);
  background: transparent;
  transition: all ease-in-out 100ms;
  line-height: normal;

  &:hover {
    border-color: var(--beige);

    &[target="_blank"]::after {
      opacity: 1;
    }
  }

  &:focus {
    border-color: var(--beige);
  }

  &[target="_blank"] {
    margin-right: .5rem;

    &::after {
      content: "→";
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      color: var(--beige);
      opacity: .5;
      font-size: .6em;
      transform: translate(110%, -85%) rotate(-45deg);
      transition: opacity ease-in-out 100ms;
    }
  }
}

button,
a.button {
  display: inline-block;
  height: 3rem;
  border: 0;
  border-radius: .25rem;
  background: var(--beige);
  color: var(--brown);
  padding: 1rem 1.4rem;
  font-size: .8rem;
  font-weight: 600;
  line-height: 1rem;
  text-transform: uppercase;
  transition: background ease-in-out 100ms;

  &:hover {
    background: var(--beige);
  }

  > .arrow {
    position: relative;
    top: -1px;
    margin: 0 5px 0 0;
    font-size: 16px;
  }
}

.alert {
  margin: 0 0 4rem;
  padding: 2rem;
  border-radius: 6px;
  background: var(--mooving-gradient);
  background-size: var(--mooving-gradient-size);
  animation: var(--mooving-gradient-animation);
  text-align: center;
  font-size: 1.1rem;
  line-height: 1.5rem;
  font-weight: 700;
  text-shadow: 0 0 25px rgba(0, 0, 0, .5);
}

.icon {
  position: relative;
  top: 6px;
  display: inline-block;
  transform: scale(0.8);
  margin-right: 4px;
  filter: invert(100%) sepia(4%) saturate(5884%) hue-rotate(28deg) brightness(111%) contrast(98%);
}